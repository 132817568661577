import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/layout/Layout.vue'),
    children: [
      {
        path: '/',//默认二级路由
        name: 'home',
        component: () => import('../views/home/Home.vue'),
      },
      {
        path: '/events',
        name: 'events',
        component: () => import('../views/events/Events.vue'),
      },
      {
        path: '/gallery',
        name: 'gallery',
        component: () => import('../views/gallery/Gallery.vue'),
      }
    ]
  },
  {
    path: '/home',
    redirect: '/'
  }

]

const router = new VueRouter({
  routes,
  //打开新页面的时候，会跳转到页面的顶部
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router

