import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 样式初始化
import 'normalize.css'

//引入bootstrap相关文件
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'


//VueScrollTo 滚动插件
import VueScrollTo from 'vue-scrollto'
let scrollOptions = {
  container: "body", //滚动的容器
  duration: 500, //滚动时间
  easing: "ease", //缓动类型
  offset: -50, // 滚动时应应用的偏移量。此选项接受回调函数
  force: true, // 是否应执行滚动
  cancelable: true, // 用户是否可以取消滚动
  onStart: false, // 滚动开始时的钩子函数
  onDone: false, // 滚动结束时候的钩子函数
  onCancel: false, // 用户取消滚动的钩子函数
  x: false, // 是否要在x轴上也滚动
  y: true    // 是否要在y轴上滚动
};
Vue.use(VueScrollTo, scrollOptions)


//播放器
import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)


// 图片懒加载vue-lazyload
import VueLazyload from "vue-lazyload";
const loadimage = require("./assets/images/loading.gif");
const errorimage = require("./assets/images/loading.gif");
Vue.use(VueLazyload, {
  preLoad: 1,             // 预加载高度比例，默认 1.3
  error: errorimage,      // 加载失败时图像的src
  loading: loadimage,     // 正在加载时图像的src
  attempt: 1,             // 尝试次数
});


// swiper版本5
import swiperCss from 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 第二个参数可配置默认选项
Vue.use(VueAwesomeSwiper, {
  swiperCss
})


// 自定义指令
// import slideIn from "@/utils/vSlideIn";
// Vue.directive('slide-in', slideIn)

// 图标
import '@/assets/fonts/iconfont.js'
import '@/assets/fonts/iconfont.css'

// 动画
// import animate from "animate.css";
// Vue.use(animate)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
